$theme: ekonomi;
@use "design-system" as ds;

.container {
  display: flex;
  flex-direction: column;
  gap: ds.spacing("regular");
}

.header {
  @include ds.typography(h2, $theme, $theme);
}

.button {
  border: none;
  &:hover {
    border: none;
  }
}

.economyButtonColor {
  @include ds.background(brand, ui, $theme: $theme);
  &:hover {
    @include ds.background(brand--prominent, ui, $theme: $theme);
  }
  @include ds.darkmode {
    &:hover {
      @include ds.background(brand--prominent, ui, $theme: $theme);
    }
  }
}

.coreButton {
  @include ds.background(premium, ui, $theme: $theme);
  &:hover {
    @include ds.background(premium--prominent, ui, $theme: $theme);
  }
  @include ds.darkmode {
    &:hover {
      @include ds.background(premium--prominent, ui, $theme: $theme);
    }
  }
}
