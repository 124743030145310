$theme: ekonomi;
@use "design-system" as ds;

.container {
  display: flex;
  flex-direction: column;
  max-height: 80%;
  max-height: 80vh;
  @include ds.typography(h7, $theme, $theme);
  @include ds.background(primary, $theme: $theme);
  padding-top: 0;
  margin: 0 auto;
  width: 80%;
  max-width: 100%;
  border-radius: ds.spacing("micro");
  cursor: auto;

  @media (min-width: #{ds.$screen-size--small}) {
    width: 550px;
  }
}

.contentArea {
  overflow-y: scroll;
  @include ds.pad();
}
